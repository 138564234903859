/**
 * @description Converts full URL to short, concise format.
 * @example https://brale.xyz -> brale.xyz
 * @example https://www.coinbase.com/usdc -> coinbase.com
 */

export function formatUrl(url: string) {
  url = url.replace(/^https:\/\/www\./i, "");
  url = url.replace(/^https:\/\//i, "");
  url = url.replace(/\/.*$/, "");
  return url;
}
